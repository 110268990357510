import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import parse  from 'html-react-parser';
import { last } from "lodash";
import { useLocation } from "react-router-dom";
// import icon from '../../resources/img/logo-16.png';

interface IProps {
    tempTitle?: string
}

const titles: {[url: string]: string} = {};

const abortController = new AbortController();

export function HelmetTitle(props: IProps) {
    const [title, setTitle] = useState<string|undefined>(props.tempTitle);
    const { pathname } = useLocation();

    let url = window.location.toString();
    url = url.split('localhost').join('joshua.wpprovider.nl');
    url = url.split('http://').join('https://');
    url = url.split(':3000').join('');
    url = url.includes('?') ? url.split('?').join('?static=true&') : `${url}?static=true`;
    url = url.includes('/?') ? url : url.split('?').join('/?');
    // const separator = url.includes('?') ? '&' : '?';
    // url = `${url}${separator}static=true`;


    useEffect(() => {
        let mounted = true;
        if (titles[url]) {
            setTitle(titles[url]);
        } else {
            fetch(url, {
                signal: abortController.signal,
            })
                .then((result: Response) => result.text())
                .then((html: string) => {
                    if (!mounted) return;
                    const newTitle = html.split("\n").filter((line: string) => {
                        return line.split('<title').length > 1;
                    })[0];
                    const parsed = parse(newTitle.split('>')[1].split('<')[0]).toString();
                    setTitle(parsed);
                    if (!titles[url]) {
                        titles[url] = parsed;
                    }
                }).catch((e: any) => {
                    console.error(e);
            });
        }
        return () => {
            mounted = false;
            // abortController.abort();
        }
    }, [url, pathname]);

    if (!title) {
        return <></>;
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                {/*<link rel="icon" type="image/png" sizes="16x16"  href={icon} />*/}
            </Helmet>
        </HelmetProvider>
    )
}
