import { createBrowserRouter, Outlet } from "react-router-dom";
import { lazy } from "react";
import { PageLoader } from "./components/PageLoader/PageLoader";
import { Body } from "./components/Body/Body";
const HousePage = lazy(() => import("./pages/HousePage/HousePage"));
const FrontPage = lazy(() => import("./pages/FrontPage/FrontPage"));
const Listings = lazy(() => import("./pages/Listings/Listings"));

export const router = createBrowserRouter([{
    path: '/',
    element: <Body />,
    children: [{
        path: '/',
        element: <PageLoader><FrontPage /></PageLoader>
    }, {
        path: '/aanbod',
        element: <Outlet />,
        children: [{
            path: '',
            element: <PageLoader><Listings /></PageLoader>
        }, {
            path: ':slug',
            element: <PageLoader><HousePage /></PageLoader>
        }]
    }]
}]);
