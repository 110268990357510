import React from "react";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-amber/theme.css";
import { ReactQueryDevtools } from "react-query/devtools";
import { locale, addLocale } from 'primereact/api';

addLocale('nl', {
    // firstDayOfWeek: 1,
    firstDayOfWeek: 1,
    // dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
    // dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
    // dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    dayNamesMin: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
    // monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
    // monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    monthNamesShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
    // today: 'Hoy',
    today: 'Vandaag',
    // clear: 'Limpiar',
    clear: 'Wissen',

});

locale('nl');



export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        }
    }
})


export function AppContainer(props: React.PropsWithChildren<{}>) {
    // if running on port 3000, show the dev tools
    const showDevTools = window.location.port === '3000';
    return (
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <PrimeReactProvider value={{
                    ripple: true
                }}>
                    {props.children}
                </PrimeReactProvider>
                {showDevTools && <ReactQueryDevtools initialIsOpen={false}/>}
            </QueryClientProvider>
        </RecoilRoot>
    )
}
