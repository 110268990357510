import React from "react";
import { TopBar } from "./TopBar/TopBar";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { HelmetTitle } from "../HelmetTitle/HelmetTitle";

export function Body() {
    return (
        <>
            <HelmetTitle/>
            <div className="body-wrapper">
                <TopBar/>
                <Outlet/>
                <ScrollRestoration/>
            </div>
        </>
    )
}
