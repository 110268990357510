import React from "react";
import classNames from "classnames";

interface IProps {
    justify?: "start" | "end" | "center" | "between" | "around";
}

export function TopBarItem({ justify, children }: React.PropsWithChildren<IProps>) {
    const classes = classNames({
        'top-bar-item': true,
        "justify-start": justify === "start",
        "justify-end": justify === "end",
        "justify-center": justify === "center",
        "justify-between": justify === "between",
        "justify-around": justify === "around",
    });

    return (
        <div className={classes}>
            { children }
        </div>
    )
}
