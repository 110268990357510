import { useEffect, useState } from "react";

// breakpoints can be singular, or a range (e.g. 'sm', 'md-lg', 'xs-md') - the range always consists of two breakpoints, the first being the min, the second being the max
type TBreakpointCombination = 'xs' | 'sm' | 'md' | 'lg' | 'xs-sm' | 'sm-md' | 'md-lg' | 'xs-md' | 'sm-lg' | 'xs-lg';

type IBreakpoint = {
    [key in ('xs' | 'sm' | 'md' | 'lg')]: {
        min: number;
        max: number | null;
    };
};

const breakpoints: IBreakpoint = {
    xs: {min: 0, max: 360},
    sm: {min: 361, max: 960},
    md: {min: 961, max: 1400},
    lg: {min: 1401, max: null},
}

export const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState<('xs' | 'sm' | 'md' | 'lg')>('lg');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            for (const [key, value] of Object.entries(breakpoints)) {
                if (value.min <= width && (!value.max || value.max >= width)) {
                    setBreakpoint(key as ('xs' | 'sm' | 'md' | 'lg'));
                    break;
                }
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const showFor = (breakpointSets: TBreakpointCombination[] | TBreakpointCombination) => {
        if (!Array.isArray(breakpointSets)) {
            breakpointSets = [breakpointSets];
        }
        for (const breakpointSet of breakpointSets) {
            const set = breakpointSet.split('-') as ('xs' | 'sm' | 'md' | 'lg')[];
            if (set.length === 1) {
                if (set[0] === breakpoint) {
                    return true;
                }
            } else if (set.length === 2) {
                const min = breakpoints[set[0]].min;
                const max = breakpoints[set[1]].max;
                if (min <= window.innerWidth && (!max || max >= window.innerWidth)) {
                    return true;
                }
            } else {
                throw new Error('Invalid breakpoint set');
            }

        }
        return false;
    }

    return {
        breakpoint,
        showFor,
    }
}
