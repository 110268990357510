import React, { Suspense } from "react";
import { SpinnerPage } from "../SpinnerPage/SpinnerPage";

export function PageLoader({ children }: { children: React.ReactNode }) {
    return (
        <Suspense fallback={<SpinnerPage />}>
            {children}
        </Suspense>
    )
}
