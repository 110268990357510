import React from "react";
import "./SpinnerPage.scss";
import { Spinner } from "../Spinner/Spinner";

export function SpinnerPage() {
    return (
        <div className="spinner-page flex align-items-center justify-content-center">
            <Spinner />
        </div>
    )
}
