import React from "react";
import "./TopBar.scss";
import { TopBarItem } from "./TopBarItem/TopBarItem";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { NavLink } from "react-router-dom";
import { Button } from "primereact/button";
import logo from "../../../assets/img/logo.svg";

interface IProps {

}

export function TopBar(props: IProps) {
    const { showFor } = useBreakpoint();
    return (
        <div className="top-bar">
            <TopBarItem justify="start">
                <NavLink to={"/"} className={"logo-wrapper"}>
                    <img src={logo} alt="logo" />
                </NavLink>
            </TopBarItem>
            { showFor("md-lg") && (
                <TopBarItem justify="start">
                    {/*<span>menu placeholder</span>*/}
                    <Button icon="pi pi-bars" rounded text severity={"secondary"} />
                </TopBarItem>
            )}
            { showFor('sm') && (
                <TopBarItem justify="end">
                {/*    hamburger button*/}
                    <Button icon="pi pi-bars" rounded text severity={"secondary"} />
                </TopBarItem>
            )}
        </div>
    )
}
