import React from "react";
import "./Spinner.scss";
import { ProgressSpinner } from "primereact/progressspinner";

export function Spinner() {
    return (
        <ProgressSpinner
            className={"uwcasa--spinner"}
            fill={'var(--surface-ground)'}
            strokeWidth="6"
            pt={{
                circle: {
                    style: {
                        animation: 'p-progress-spinner-dash 1.5s ease-in-out infinite, uwcasa--custom-spinner-color-animation 6s ease-in-out infinite'
                    }
                }
            }}
        />
    )
}
